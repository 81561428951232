<template>
  <b-row class="mb-1">
    <b-button
      v-if="$can('Upsell', 'Campaigns > Campaign')"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-upsell
      class="float-right ml-1"
      variant="outline-primary"
      @click="$emit('upsellSuccess', false)"
    >
      Upsell
    </b-button>
    <UpsellModal
      v-if="$can('Upsell', 'Campaigns > Campaign')"
      @upsellSuccess="$emit('upsellSuccess', true)"
    />
    <b-button
      v-if="$can('Retain', 'Campaigns > Campaign') && isRetainDisabled"
      v-b-modal.modal-retain
      v-b-tooltip.hover
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      :disabled="isRetainDisabled"
      title="Able to retain this campaign on maximum 30 days before last cycle ended."
      class="float-right ml-1"
    >
      Retain
    </b-button>
    <b-button
      v-else-if="$can('Retain', 'Campaigns > Campaign')"
      v-b-modal.modal-retain
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      class="float-right ml-1"
    >
      Retain
    </b-button>
    <RetainModal v-if="$can('Retain', 'Campaigns > Campaign')" />
    <b-button
      v-if="$can('Auto Sync', 'Campaigns > Campaign') && !isNoSyncCampaign"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="float-right ml-1"
      variant="outline-primary"
      @click="autoSync"
    >
      Auto Sync
    </b-button>
    <b-button
      v-if="$can('Delete Sync', 'Campaigns > Campaign') && !isNoSyncCampaign"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="float-right ml-1"
      variant="outline-primary"
      disabled="true"
      @click="deleteSync"
    >
      Delete Sync
    </b-button>
    <b-button
      v-if="$can('Media History', 'Campaigns > Campaign')"
      v-b-modal.modal-media-history
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      class="float-right ml-1"
    >
      Media History
    </b-button>
    <MediaHistoryModal />
    <b-button
      v-if="$can('Ack', 'Campaigns > Campaign') && $store.state.heroAiCampaign.isPendingApproval"
      v-b-modal.modal-pending-approval
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="btn-pending float-right ml-1"
    >
      Pending Acknowledge
    </b-button>
    <div v-if="$can('Ack', 'Campaigns > Campaign') && $store.state.heroAiCampaign.isPendingApproval">
      <PendingApproval
        :modal-id="'modal-pending-approval'"
        :kpi-unit-options="kpiUnitOptions"
      />
    </div>

    <b-dropdown
      v-if="isOthersDropdownShow"
      ref="otherDropdown"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="float-right ml-1"
      split
      text="Others"
      variant="outline-primary"
    >
      <template v-if="$can('Export', 'Campaigns > Campaign') ">
        <b-dropdown-item
          :disabled="isExporting"
          @click.capture.native.stop="exportCampaign"
        >
          <b-spinner v-if="isExporting" small />
          Export
        </b-dropdown-item>
        <b-dropdown-divider />
      </template>
      <template v-if="$can('Import', 'Campaigns > Campaign')">
        <b-dropdown-item v-b-modal.modal-import>
          Import
        </b-dropdown-item>
        <b-dropdown-divider />
      </template>
      <template v-if="$can('Import', 'Campaigns > Campaign')">
        <b-dropdown-item
          v-b-modal="'import-planner-template-modal'"
          @click="assignCampaignId"
        >
          Import Planner Template
        </b-dropdown-item>
        <b-dropdown-divider />
      </template>
      <template v-if="$can('Allocate', 'Campaigns > Campaign')">
        <b-dropdown-item
          v-b-modal="'allocate-budget-modal'"
          @click="assignCampaignId"
        >
          Allocate
        </b-dropdown-item>
        <b-dropdown-divider />
      </template>
    </b-dropdown>

    <ImportModal @importSuccess="$emit('importSuccess', $event)" />
    <ImportPlannerTemplateModal :campaign-id="campaignId" @importSuccess="$emit('importSuccess', $event)" />
    <AutoSyncModal :auto-sync-data="autoSyncData" />
    <AllocateBudgetModal :campaign-id="campaignId" @success="$emit('importSuccess', $event)" />
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import Constant from '@/utils/Constant'
import AxiosService from '@/services/AxiosService'
import SweetAlert from '@/services/SweetAlert'
import AllocateBudgetModal from '@/views/heroai/campaigns/components/view/Campaign/AllocateBudgetModal.vue'
import ImportPlannerTemplateModal from '@/views/heroai/campaigns/components/view/Campaign/ImportPlannerTemplateModal.vue'
import { mapState } from 'vuex'
import RetainModal from './RetainModal.vue'
import MediaHistoryModal from './MediaHistoryModal.vue'
import UpsellModal from './UpsellModal.vue'
import ImportModal from './ImportModal.vue'
import AutoSyncModal from './AutoSyncModal.vue'
import PendingApproval from './PendingApproval/Popup.vue'

export default {
  components: {
    ImportPlannerTemplateModal,
    RetainModal,
    MediaHistoryModal,
    UpsellModal,
    ImportModal,
    AutoSyncModal,
    AllocateBudgetModal,
    PendingApproval,
  },
  directives: {
    Ripple,
  },
  props: {
    kpiUnitOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      autoSyncData: [],
      campaignId: '',
      isExporting: false,
    }
  },
  computed: {
    ...mapState({
      isNoSyncCampaign: state => state.heroAiCampaign.campaign.is_no_sync_campaign,
    }),

    isRetainDisabled() {
      // const campaignCycle = Store.getters['heroAiCampaign/getCampaignCycle']
      //
      // if (campaignCycle && campaignCycle.length > 0) {
      //   return dateDiff(new Date(), campaignCycle[campaignCycle.length - 1].end_date) >= 30
      // }

      return false
    },

    isOthersDropdownShow() {
      return (
        this.$can('Import', 'Campaigns > Campaign')
          && this.$can('Export', 'Campaigns > Campaign')
          && this.$can('Allocate', 'Campaigns > Campaign')
          && this.$can('Delete', 'Campaigns > Campaign')
      )
    },
  },
  methods: {
    async deleteCampaign() {
      this.$swal({
        title: 'Are you sure you want to delete?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        showCloseButton: true,
        allowOutsideClick: false,
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await AxiosService.delete(`${Constant.apiURL.campaign.global}/${this.$route.params.id}`)
          if (response.status) {
            this.$swal('Deleted', response.message, 'success')
            this.$router.push({ name: 'campaigns-list' })
          }
        }
      })
    },
    async exportCampaign() {
      if (this.isExporting) return
      this.isExporting = true

      const response = await AxiosService.get(`${Constant.apiURL.exportCampaign}/${this.$route.params.id}/exportMediaPlans`)
      if (response.status) {
        window.open(response.downloadableFile, '_blank')
      }

      this.$refs.otherDropdown.hide()
      this.isExporting = false
    },
    async autoSync() {
      const alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to sync ads campaigns?' })

      if (alertResult.value) {
        this.$swal({ ...SweetAlert.loading, text: 'Syncing ads campaigns.' })

        const response = await AxiosService.get(`${Constant.apiURL.autoSync}/${this.$route.params.id}`)

        if (response.status) {
          this.$swal().close()
          this.autoSyncData = response.data
          this.$emit('autoSyncSuccess', true)
          this.$bvModal.show('modal-autosync')
        }
      }
    },
    async deleteSync() {
      const alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to delete all ads campaign syncing?' })

      if (alertResult.value) {
        this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are deleting the sync.' })

        const response = await AxiosService.delete(`analytic-campaign-mapping/campaign/${this.$route.params.id}`)
        await this.$swal({ ...SweetAlert.success, text: response.message })
      }
    },

    assignCampaignId() {
      this.campaignId = this.$route.params.id
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-pending {
  color: #d81b60 !important;
  background-color: white !important;
  border: 1px solid #d81b60 !important;
  &:hover {
    box-shadow: 0 8px 25px -8px #fec8d8 !important;
  }
}
</style>
