<template>
  <b-modal
    id="import-planner-template-modal"
    ref="import-planner-template-modal"
    title="Import Planner Template"
    centered
    hide-footer
    no-close-on-backdrop
    @show="doResetModal"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <b-form @submit.stop.prevent>
      <b-row>
        <b-col>
          <b-form-file
            v-model="uploadedFile"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".xlsx"
            class="mb-1"
          />
        </b-col>
      </b-row>

      <!-- !! Footer -->
      <hr>
      <b-row>
        <b-col cols="6" sm="3">
          <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
            Back
          </HeroButtonAction>
        </b-col>

        <template v-if="!passValidation">
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction
              type="button"
              variant="primary"
              :disabled="!uploadedFile"
              @click="doValidate"
            >
              Validate
            </HeroButtonAction>
          </b-col>
        </template>

        <template v-if="passValidation">
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction
              type="button"
              variant="primary"
              @click="doImport"
            >
              Import
            </HeroButtonAction>
          </b-col>
        </template>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import ErrorService from '@/services/ErrorService'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    HeroButtonAction,
  },
  props: {
    campaignId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      uploadedFile: null,
      passValidation: false,
    }
  },
  methods: {
    doCloseModal() {
      this.$refs['import-planner-template-modal'].hide()
      this.$emit('success')
    },

    doResetModal() {
      this.uploadedFile = null
      this.passValidation = false
      this.$emit('importSuccess', false)
    },

    async doValidate() {
      try {
        let alertResult

        if (!this.uploadedFile) {
          alertResult = await this.$swal({ ...SweetAlert.warning, text: 'Please choose a file to import.' })
          return
        }

        this.showOverlay = true

        const formData = new FormData()

        formData.append('uploadedFile', this.uploadedFile)

        const response = await axiosInstance.post(`/planner-template/validate/${this.campaignId}`, formData)

        alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

        if (alertResult.value) {
          this.passValidation = response.data['pass-validation']
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.errorCycleOverlapping, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    async doImport() {
      try {
        this.showOverlay = true

        const response = await axiosInstance.post(`/planner-template/import/${this.campaignId}`, null)

        const alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

        if (alertResult.value) {
          this.$emit('importSuccess', true)
          this.doResetModal()
          this.doCloseModal()
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },
  },
}
</script>
