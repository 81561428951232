<template>
  <b-tab lazy title="HeroVisionX Filter">
    <b-row class="mb-2">
      <b-col cols="12" sm="2" md="2">
        <HeroButtonModal
          v-if="$can('Edit', 'Campaigns > HeroVisionX Filter')"
          v-b-modal="'herovision-filter-create-modal'"
          type="button"
          variant="success"
        >
          Create Filter
        </HeroButtonModal>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col cols="12">
        <b-table
          show-empty
          striped
          sticky-header="100%"
          :responsive="true"
          :items="items"
          :fields="tableConfig.fields"
          :per-page="0"
          :sort-by="tableConfig.sortBy"
          :sort-direction="tableConfig.sortDirection"
          :no-sort-reset="true"
          :no-local-sorting="true"
        >
          <template #cell(action)="data">
            <!-- !! Normal Flag -->
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none action-button"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle"
                />
              </template>

              <!-- !! Edit Button -->
              <b-dropdown-item
                v-if="$can('Edit', 'Campaigns > HeroVisionX Filter')"
                v-b-modal="'herovision-filter-edit-modal'"
                @click="doEditData(data.item)"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>

              <!-- !! Delete Button -->
              <b-dropdown-item
                v-if="$can('Edit', 'Campaigns > HeroVisionX Filter')"
                @click="doDeleteData(data.item)"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template #cell(forward_number)="data">
            <div v-html="convertList(data.item.forward_number)" />
          </template>

          <template #cell(email_lead)="data">
            <div v-html="convertList(data.item.email_lead)" />
          </template>

          <template #cell(sms_lead)="data">
            <div v-html="convertList(data.item.sms_lead)" />
          </template>
        </b-table>
      </b-col>
    </b-row>

    <HeroVisionFilterCreateModal @success="doLoadData" />
    <HeroVisionFilterEditModal :filter="editFilter" @success="doLoadData" />
  </b-tab>
</template>

<script>
import moment from 'moment'
import ErrorService from '@/services/ErrorService'
import {
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import SweetAlert from '@/services/SweetAlert'
import HeroButtonModal from '@/views/components/form/buttons/HeroButtonModal.vue'
import HeroVisionFilterCreateModal from '@/views/heroai/campaigns/components/view/herovision-filter/HeroVisionFilterCreateModal.vue'
import HeroVisionFilterEditModal from '@/views/heroai/campaigns/components/view/herovision-filter/HeroVisionFilterEditModal.vue'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    HeroButtonModal,
    HeroVisionFilterCreateModal,
    HeroVisionFilterEditModal,
  },
  data() {
    return {
      showOverlay: false,
      campaignId: this.$route.params.id ? this.$route.params.id : '',
      editFilter: {},

      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            ...this.$can('Edit', 'Campaigns > HeroVisionX Filter') && {
              label: 'action',
              key: 'action',
              stickyColumn: false,
            },
          },
          {
            label: 'filter name',
            key: 'name',
            sortable: false,
          },
          {
            label: 'did phone',
            key: 'did_phone',
            sortable: false,
          },
          {
            label: 'landing page field',
            key: 'landing_page_field',
            sortable: false,
          },
          {
            label: 'landing page value',
            key: 'landing_page_value',
            sortable: false,
          },
          {
            label: 'forward number',
            key: 'forward_number',
            sortable: false,
          },
          {
            label: 'email language',
            key: 'email_language_name',
            sortable: false,
          },
          {
            label: 'email',
            key: 'email_lead',
            sortable: false,
          },
          {
            label: 'sms language',
            key: 'sms_language_name',
            sortable: false,
          },
          {
            label: 'sms',
            key: 'sms_lead',
            sortable: false,
          },
        ],
      },
    }
  },
  async mounted() {
    await this.$store.dispatch('LanguageOptions/fetchOptions')
    await this.doLoadData()
  },
  methods: {
    async doLoadData() {
      try {
        this.showOverlay = true

        const response = await axiosInstance.get(`/herovision-filter/list/${this.campaignId}`)
        this.items = response.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    doEditData(filter) {
      this.editFilter = { ...filter }
    },

    async doDeleteData(filter) {
      try {
        const alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to delete this filter information?' })

        if (alertResult.value) {
          this.showOverlay = true

          const response = await axiosInstance.delete(`/herovision-filter/delete/${filter.id}`)

          await this.$swal({ ...SweetAlert.success, text: response.data.message })
          await this.doLoadData()
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    // แสดงผลข้อมูลอาร์เรย์ในรูปแบบลิส
    convertList(list) {
      let newList = '<ul>'

      list.forEach(item => {
        newList = `${newList}<li>${item}</li>`
      })

      newList = `${newList}</ul>`

      return newList
    },
  },
}
</script>
