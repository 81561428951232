<template>
  <app-collapse-item
    :is-visible="true"
    title="Solution Plan"
    :header-style="{
      position: 'sticky',
      top: '80px',
      background: 'white',
      zIndex: 2,
    }"
  >
    <template v-slot:action>
      <b-row>
        <template v-if="$store.state.heroAiCampaign.historyState > 0">
          <b-form-group
            label-for="media-history-name"
            label-cols-md="0"
            class="ml-0 mr-1 mb-0"
          >
            <template v-slot:label>
              <span style="font-size: 1rem; font-weight: 600; line-height: 34px;">
                {{ $store.state.heroAiCampaign.historySelected.mediaHistoryName }}
              </span>
            </template>
          </b-form-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="success"
            class="ml-0 h-25 mr-2 mb-0"
            @click="($store.state.heroAiCampaign.historyState = 0)"
          >
            &lt; Back to current plan
          </b-button>
        </template>
        <template v-else-if="(isEdit && $store.state.heroAiCampaign.historyState === 0)">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal="'cycle-filter-modal'"
            variant="outline-primary"
            class="d-flex h-25 mr-1"
          >
            <feather-icon
              icon="FilterIcon"
              size="16"
            />
            <span class="ml-1">{{ cycleListFilterMap[cycleFilter.cycleListFilter] }}</span>
          </b-button>
          <b-form-group
            label-for="campaign-budget"
          >
            <template v-slot:label>
              <span style="font-size: 1rem; font-weight: 600; margin-right: 30px">
                Campaign Budget
              </span>
            </template>
            <div id="campaign-budget">
              {{ formatMoney(campaign_budget) }}
            </div>
          </b-form-group>
          <b-form-group
            label-for="remaining"
          >
            <template v-slot:label>
              <span style="font-size: 1rem; font-weight: 600; margin-right: 20px;">
                Remaining
              </span>
            </template>
            <div id="remaining" class="ml-0 mr-5 mb-0">
              {{ formatMoney(updatedRemaining) }}
            </div>
          </b-form-group>
          <HeroVueSelectMultiple
            v-show="false"
            id="cycle"
            v-model="cycles"
            :options="cycleOptions"
            class="ml-0 mr-1 mb-0"
          />
          <b-button
            v-if="$can('Add Cycle', 'Campaigns > Campaign > Solution Plan') && !hasAddedNewCycle"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            class="ml-0 h-25 mr-1 mb-0"
            @click="(addCycle); $bvModal.show('modal-add-products-solution-plan')"
          >
            Add Cycle
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="ml-0 mr-1 h-25 mb-0"
            @click="saveCycle"
          >
            Save
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :disabled="isEditLoading"
            variant="outline-primary"
            class="ml-0 h-25 mb-0"
            :class="{ 'mr-1': !editSelectedCount }"
            @click="onCancelEdit"
          >
            <b-spinner v-if="isEditLoading" small />
            Cancel
          </b-button>
          <div
            v-if="editSelectedCount"
            class="text-delete-selected text-danger font-weight-bold h-25"
          >
            Delete selected ({{ editSelectedCount }})
          </div>
        </template>
        <template v-else>
          <b-badge v-if="clientPayForMedia" variant="info" class="mr-1 height mb-0" title="Client Paying for Media">
            Client Paying for Media
          </b-badge>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal="'cycle-filter-modal'"
            variant="outline-primary"
            class="d-flex mr-1"
          >
            <feather-icon
              icon="FilterIcon"
              size="16"
            />
            <span class="ml-1">{{ cycleListFilterMap[cycleFilter.cycleListFilter] }}</span>
          </b-button>
          <b-form-group
            label-for="client-segment"
            label-cols-md="0"
            class="ml-0 mr-1 margin mb-0"
          >
            <template v-slot:label>
              <span style="font-size: 1rem; font-weight: 600;">
                Client Segment:
              </span>
            </template>
          </b-form-group>
          <b-badge variant="info" class="mr-1 height mb-0" style="cursor: default;" title="Client Segment">
            {{ $store.state.heroAiCampaign.opportunity_overview.client_segment }}
          </b-badge>
          <b-form-group
            label-for="pricing-model"
            label-cols-md="0"
            class="ml-0 mr-1 margin mb-0"
          >
            <template v-slot:label>
              <span style="font-size: 1rem; font-weight: 600;">
                Pricing Model:
              </span>
            </template>
          </b-form-group>
          <b-badge variant="danger" class="mr-1 height mb-0" style="cursor: default;" title="Service Based">
            {{ $store.state.heroAiCampaign.opportunity_overview.pricing_model }}
          </b-badge>
          <b-form-group
            label-for="sf-stage"
            label-cols-md="0"
            class="ml-0 mr-1 margin mb-0"
          >
            <template v-slot:label>
              <span style="font-size: 1rem; font-weight: 600;">
                SF Stage:
              </span>
            </template>
          </b-form-group>
          <b-badge :variant="calculateSfStageVariant($store.state.heroAiCampaign.opportunity_overview.stage_name)" class="mr-1 height mb-0" style="cursor: default;" title="Go Live Approval">
            {{ $store.state.heroAiCampaign.opportunity_overview.stage_name }}
          </b-badge>
          <b-button
            v-if="$can('Edit', 'Campaigns > Campaign > Solution Plan')"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :disabled="isEditLoading"
            variant="primary"
            class="ml-0 h-25 mr-2 mb-0"
            @click="updateCycles"
          >
            <b-spinner v-if="isEditLoading" small />
            Edit
          </b-button>
        </template>
      </b-row>
    </template>
    <!-- <div v-if="campaignCycle && campaignCycle.lenght > 0"> -->
    <div v-if="isPageLoad && kpiUnitOptions && kpiUnitOptions.length > 0">
      <div v-if="isEdit">
        <validation-observer ref="updateCycleRules" tag="form">
          <MediaPlanEdit
            v-for="(cycle, cycleindex) in getCampaignCycle()"
            :key="'editindex'+cycleindex"
            :active-channel-id="activeChannelId"
            :campaign-enddate="campaignEnddate"
            :cycles="cycles"
            :campaign-startdate="campaignStartdate"
            :cycle-index="cycleindex"
            :cycle="cycle"
            :client-pay-for-media="clientPayForMedia"
            :kpi-options="kpiUnitOptions"
            :allproducts="allproducts"
            :product-options="productOptions"
            :campaign-product-options="campaignProductOptions"
            :is-cycle-show="isCycleShow(cycle)"
            @selectChange="onEditCycleSelectChange(cycle.media_plan_id)"
          />
        </validation-observer>
      </div>
      <div v-else>
        <MediaPlanView
          v-for="(cycle, cycleindex) in getActiveCampaignCycle()"
          :key="'viewindex'+cycleindex"
          :initial-visible-cycle="cycleindex === 0"
          :kpi-options="kpiUnitOptions"
          :cycle="cycle"
          :cycle-filter="cycleFilter"
          :is-cycle-show="isCycleShow(cycle)"
          @cycleProduct="cycleProductSuccess"
        />
        <cycle-product-campaign :product="popupProduct" />
        <cycle-product-channel :product="popupProduct" />
        <PromptListModal
          :product-id="popupProduct.product_id"
          :campaign-id="$route.params.id"
          :campaign-product-id="popupProduct.campaign_product_id"
        />
      </div>
    </div>
    <!-- </div> -->
    <AddCycleVue :campaign-product-options="campaignProductOptions" :remaining="remaining" :client-pay-for-media="clientPayForMedia" :campaign-enddate="campaignEnddate" :campaign-startdate="campaignStartdate" :campaignproducts="campaignproducts" :kpi-options="kpiUnitOptions" :allproducts="allproducts" :product-options="productOptions" @cycleAdded="getMediaCampaign" />
    <CycleFilterModal
      :initial-cycle-list-filter="cycleListFilterDefault"
      @onSubmit="onCycleFilter"
    />
  </app-collapse-item>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroVueSelectMultiple from '@/views/components/form/selects/HeroVueSelectMultiple.vue'
import SweetAlert from '@/services/SweetAlert'
import AxiosService from '@/services/AxiosService'
import ErrorService from '@/services/ErrorService'
import Constant from '@/utils/Constant'
import CycleDropdownFactory from '@/factories/Campaign/CycleDropdown'
import DataFormatService from '@/services/DataFormatService'
import CategoryDropdownFactory from '@/factories/Campaign/CategoryDropdown'
import { ValidationObserver } from 'vee-validate'
import { BRow, BSpinner } from 'bootstrap-vue'
import Toastification from '@/services/Toastification'
import { doubleRaf } from '@/utils/helper'
import PromptListModal
from '@/views/heroai/campaigns/components/view/Campaign/MediaPlan/Cycle/View/PromptListModal.vue'
import axiosInstance from '@/libs/axiosInstance'
import MediaPlanView from './MediaPlan/View.vue'
import MediaPlanEdit from './MediaPlan/Edit.vue'
import AddCycleVue from './MediaPlan/Cycle/Edit/AddCycle.vue'
import CycleProductCampaign from './MediaPlan/CycleProductCampaign.vue'
import CycleProductChannel from './MediaPlan/CycleProductChannel.vue'
import CycleFilterModal from './CycleFilterModal.vue'

export default {
  components: {
    PromptListModal,
    BSpinner,
    BRow,
    AppCollapseItem,
    HeroVueSelectMultiple,
    AddCycleVue,
    MediaPlanView,
    MediaPlanEdit,
    CycleProductCampaign,
    CycleProductChannel,
    ValidationObserver,
    CycleFilterModal,
  },
  directives: {
    Ripple,
  },
  props: {
    upsellSuccess: {
      type: Boolean,
      default: () => false,
    },
    importSuccess: {
      type: Boolean,
      default: () => false,
    },
    tabIndex: {
      type: Number,
      required: true,
    },
    kpiUnitOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      popupProduct: {},
      index: [],
      remaining: 0,
      campaign_budget: 0,
      campaignStartdate: '',
      campaignEnddate: '',
      sf_stage: '',
      pricing_model: '',
      isEdit: false,
      isEditLoading: false,
      campaignproducts: [],
      campaignCycle: [],
      cycleOptions: CycleDropdownFactory.createFromJsonArray([{}]),
      cycles: [],
      grandTotalNetBudget: 0,
      grandTotalNetBudgetRemaining: 0,
      updatedRemaining: 0,
      transferAmount: 0,
      clientPayForMedia: false,
      is_media: true,
      // kpiOptions: [],
      allproducts: [],
      productOptions: [],
      isPageLoad: false,
      opportunityId: '',
      activeChannelId: '0',
      campaignProductOptions: [],
      cycleFilter: {
        cycleListFilter: 'by_contract',
        start: {
          month: '',
          year: '',
        },
        end: {
          month: '',
          year: '',
        },
      },
      cycleListFilterMap: {
        by_active: 'By Active',
        by_contract: 'By Contract',
        previous_contract: 'Previous Contract',
        view_all: 'View All',
        select_date: 'Select Date',
      },
    }
  },
  computed: {
    userGroups() {
      return this.$store.getters['heroAiAuthentications/loginData'].groups || []
    },
    hasAddedNewCycle() {
      if (this.campaignCycle && this.campaignCycle.filter(x => x.isNewCycle === true).length > 0) {
        return true
      }
      return false
    },
    historyState() {
      return this.$store.state.heroAiCampaign.historyState
    },
    editSelectedCount() {
      return this.campaignCycle.filter(cycle => cycle.is_deleted).length
    },
    cycleListFilterDefault() {
      const isPMKT = this.userGroups.some(userGroup => userGroup.name === 'Facebook Optimizer')
          || this.userGroups.some(userGroup => userGroup.name === 'Google Optimizer')
      return isPMKT ? 'by_active' : 'by_contract'
    },
  },
  watch: {
    '$store.state.heroAiCampaign.isDeleteChange': {
      async handler() {
        this.isPageLoad = false
        await this.getMediaCampaign()
        this.isPageLoad = true
      },
    },
    '$store.state.heroAiCampaign.cycleTotalsRemaining': {
      handler(newValue) {
        if (this.isPageLoad) {
          const CampaignNetTotal = newValue.reduce((partialSum, a) => partialSum + a, 0)
          const campaignNetTotalHistory = this.$store.state.heroAiCampaign.cycleTotalsHistory.reduce((partialSum, a) => partialSum + a, 0)
          // console.log(CampaignNetTotal, 'CampaignNetTotal')
          // console.log(campaignNetTotalHistory, 'campaignNetTotalHistory')
          // console.log(this.grandTotalNetBudgetRemaining, 'this.grandTotalNetBudgetRemaining')
          // console.log(this.remaining, 'this.remaining')

          this.transferAmount = CampaignNetTotal - campaignNetTotalHistory

          // const dealTerms = this.$store.state.heroAiCampaign.mediaopportunity.deal_terms.model
          // const clientRequirements = this.$store.state.heroAiCampaign.mediaopportunity.client_billing.client_requirements

          // console.log(dealTerms)
          // console.log(clientRequirements)

          if (this.clientPayForMedia) {
            let netBudgetDifference = parseFloat(this.grandTotalNetBudgetRemaining) - parseFloat(CampaignNetTotal)

            // ต้องตัดทศนิยมให้เหลือสองตำแหน่งก่อน ไม่อย่างนั้นจะลบแล้วเจอเลขแปลกๆ ได้
            netBudgetDifference = netBudgetDifference.toFixed(2)

            this.updatedRemaining = parseFloat(this.remaining) + parseFloat(netBudgetDifference)
          } else {
            let netBudgetDifference = parseFloat(this.grandTotalNetBudgetRemaining) - parseFloat(CampaignNetTotal)

            // ต้องตัดทศนิยมให้เหลือสองตำแหน่งก่อน ไม่อย่างนั้นจะลบแล้วเจอเลขแปลกๆ ได้
            netBudgetDifference = netBudgetDifference.toFixed(2)

            this.updatedRemaining = parseFloat(this.remaining) + parseFloat(netBudgetDifference)
          }

          // console.log(this.updatedRemaining, 'this.updatedRemaining')
        }
      },
    },
    upsellSuccess: {
      async handler(newValue) {
        if (newValue) {
          this.isEdit = false
          await this.getMediaCampaign()
        }
      },
    },
    importSuccess: {
      async handler(newValue) {
        if (newValue) {
          this.isEdit = false
          await this.getMediaCampaign()
        }
      },
    },
    historyState: {
      handler() {
        this.isEdit = false
        this.getMediaCampaign()
      },
    },
    '$store.state.heroAiCampaign.isPendingApproval': {
      async handler(newValue, oldValue) {
        if (oldValue && !newValue) {
          this.isEdit = false
          await this.getMediaCampaign()
        }
      },
    },
    async tabIndex(value) {
      if (value === 0) {
        this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are loading solution plan.' })
        this.isPageLoad = false
        await this.getMediaCampaign()
        this.isPageLoad = true
        this.$swal().close()
      }
    },
    isEdit(newValue) {
      if (newValue) {
        this.campaignCycle = this.campaignCycle.map(cycle => ({
          ...cycle,
          is_deleted: false,
        }))

        this.$store.commit('heroAiCampaign/setCampaignCycle', this.campaignCycle)
      }
    },
  },
  created() {
    this.$root.$refs.MediaPlan = this
    // this.getOpportunityDetail()
    this.getCampaignProductOptions()
    this.formatMoney = DataFormatService.formatMoney
  },
  async mounted() {
    this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are loading solution plan.' })
    this.cycleFilter.cycleListFilter = this.cycleListFilterDefault
    this.getProductList()
    await this.getMediaCampaign()
    this.isPageLoad = true
    this.$swal().close()
  },
  methods: {
    // async getOpportunityDetail() {
    //   this.isLoading = true
    //   try {
    //     const qparam = {
    //       opportunity_id: this.$store.state.heroAiCampaign.opportunity_overview.opt_id,
    //     }
    //     const response = await AxiosService.get(Constant.apiURL.salesforceOpportunityDetail, qparam)
    //     console.log('mp')
    //     this.opportunity = new Opportunity(response.data)
    //     this.$store.state.heroAiCampaign.mediaopportunity = this.opportunity
    //   } catch (error) {
    //     await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
    //     if (error.response.status === 401) {
    //       await this.$router.push({ name: 'heroai-logout' })
    //     }
    //     if (error.response.status === 404) {
    //       await this.$router.push({ name: 'page-not-found' })
    //     }
    //   }
    //   this.isLoading = false
    // },
    async addCycle() {
      const CampaignOverview = this.$store.getters['heroAiCampaign/getCampaign']
      this.campaignStartdate = CampaignOverview.campaign_start_date
      this.campaignEnddate = CampaignOverview.campaign_end_date
    },
    updateCycles() {
      this.isEditLoading = true
      doubleRaf(async () => {
        await this.addCycle()
        this.isEdit = true
        this.isEditLoading = false
      })
    },
    onCancelEdit() {
      this.isEditLoading = true
      doubleRaf(() => {
        this.isEdit = false
        this.isEditLoading = false
      })
    },
    async getMediaCampaign() {
      try {
        let response = null
        if (this.$store.state.heroAiCampaign.historyState > 0) {
          const HistorySelected = this.$store.state.heroAiCampaign.historySelected
          const Url = `${Constant.apiURL.salesforceMediaHistoryDetail}/${this.$route.params.id}/${HistorySelected.campaignHistoryId}/${HistorySelected.mediaHistoryNumber}`
          response = await AxiosService.get(Url)
        } else {
          const qparam = {
            campaign_id: this.$route.params.id,
          }
          response = await AxiosService.get('media-plans', qparam)
        }
        this.campaignproducts = []
        this.campaignCycle = []
        const disableDates = []
        if (response && response.status && response.data) {
          this.$store.state.heroAiCampaign.isPendingApproval = response.data.is_pending_approval || false

          this.clientPayForMedia = response.data.client_pay_for_media
          this.remaining         = parseFloat(response.data.remaining_budget)
          this.updatedRemaining  = parseFloat(response.data.remaining_budget)
          this.campaign_budget   = parseFloat(response.data.campaign_budget)

          this.campaignCycle     = response.data.cycles

          this.$store.state.heroAiCampaign.cycleTotals = []
          this.$store.state.heroAiCampaign.cycleTotalsRemaining = []
          this.$store.state.heroAiCampaign.cycleTotalsHistory = []
          if (this.campaignCycle && this.campaignCycle.length > 0) {
            this.campaignCycle.forEach((cycles, cycleindex) => {
              let grandTotal = 0
              let grandTotalRemaining = 0

              this.campaignCycle[cycleindex].is_deleted = false

              cycles.products.forEach((products, productindex) => {
                products.children.forEach((childData, childindex) => {
                  if (childData.is_total === false) {
                    grandTotal += parseFloat(childData.net_budget)

                    // ถ้า ลูกค้าจ่ายเงินเอง และ เป็น Non-Media ให้คำนวน
                    // ถ้า ลูกค้าไม่ได้จ่ายเงินเอง ให้คำนวน
                    if (this.clientPayForMedia && !childData.is_media) {
                      grandTotalRemaining += parseFloat(childData.net_budget)
                    } else if (!this.clientPayForMedia) {
                      grandTotalRemaining += parseFloat(childData.net_budget)
                    }

                    this.campaignCycle[cycleindex].products[productindex].children[childindex].is_new_product = false
                    this.campaignCycle[cycleindex].products[productindex].children[childindex].is_deleted = false
                  }
                })
              })

              this.$store.state.heroAiCampaign.cycleTotals.push(grandTotal)
              this.$store.state.heroAiCampaign.cycleTotalsRemaining.push(grandTotalRemaining)
              this.$store.state.heroAiCampaign.cycleTotalsHistory.push(grandTotalRemaining)

              disableDates.push({
                from: cycles.start_date.split(' ')[0],
                to: cycles.end_date.split(' ')[0],
              })
            })

            const activestatus = this.campaignCycle.filter(campaignCycle => campaignCycle.cycle_status === 'Active')

            if (activestatus.length > 0) {
              this.activeChannelId = activestatus[0].media_plan_id
            }

            this.grandTotalNetBudget          = this.$store.state.heroAiCampaign.cycleTotals.reduce((partialSum, a) => partialSum + a, 0)
            this.grandTotalNetBudgetRemaining = this.$store.state.heroAiCampaign.cycleTotalsRemaining.reduce((partialSum, a) => partialSum + a, 0)

            const lastCycle = this.campaignCycle[this.campaignCycle.length - 1]

            lastCycle.products.forEach(products => {
              if (products.children && products.children.length > 0) {
                const childrens = products.children.filter(x => x.is_total === false)

                childrens.forEach(childData => {
                  const newChildData = { ...childData }
                  newChildData.is_new_product = false
                  newChildData.is_deleted = false
                  this.campaignproducts.push(newChildData)
                })
              }
            })

            this.$store.commit('heroAiCampaign/setMediaCampaignRemaining', this.remaining)
            this.$store.commit('heroAiCampaign/setCampaignCycle', this.campaignCycle)

            this.cycleOptions = CycleDropdownFactory.createFromJsonArray(this.campaignCycle)
            this.cycles = []

            if (this.campaignCycle && this.campaignCycle.length > 0) {
              this.campaignCycle.forEach(cycle => {
                this.cycles.push(cycle.media_plan_id)
              })
            }

            this.cycles.forEach((cycle, index) => {
              if (this.cycleOptions.findIndex(x => x.value === cycle) < 0) {
                this.cycles.splice(index, 1)
              }
            })
          }
        }

        this.$store.commit('heroAiCampaign/setCycleDisableDates', disableDates)
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
    },
    async updateCycle(Payload) {
      this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are saving solution plan.' })

      const response = await AxiosService.post(`media-plans/${this.$route.params.id}/update-request`, null, Payload)
      if (response.status) {
        this.$swal({ ...SweetAlert.success, text: response.message })
        this.getMediaCampaign()
        this.isEdit = !this.isEdit
      }
    },
    async saveCycle() {
      this.$store.state.heroAiCampaign.isAddProduct = false
      await this.$refs.updateCycleRules.validate()
      await this.$refs.updateCycleRules.validate().then(async success => {
        if (success) {
          this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are checking the pending acknowledge.' })

          this.campaignCycle = this.$store.getters['heroAiCampaign/getCampaignCycle']

          if (this.updatedRemaining < 0) {
            this.$swal('You are exceeding your limit')
          } else {
            try {
              const Payload = {
                campaign_id: this.$route.params.id,
                remaining: this.updatedRemaining,
                cycles: this.campaignCycle,
                client_pay_for_media: this.clientPayForMedia,
                transfer_amount: this.transferAmount,
              }
              if (this.$route.name === 'campaign-detail') {
                const responseExist = await AxiosService.get(`${Constant.apiURL.salesforceCampaignPendingRequestExist}/${this.$route.params.id}`)

                if (responseExist.status && responseExist.data.pending_request_exist) {
                  this.$swal({
                    title: 'Approval request already exist. Do you want to override?',
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes',
                    showCloseButton: true,
                  }).then(async resultconfirm => {
                    if (resultconfirm.isConfirmed) {
                      this.updateCycle(Payload)
                    }
                  })
                } else {
                  this.updateCycle(Payload)
                }
              } else if (this.$route.name === 'retain-campaign') {
                const response = await AxiosService.patch(`${Constant.apiURL.updateRetainCampaign}/${this.$route.params.id}`, null, Payload)

                if (response.status) {
                  this.$swal({ ...SweetAlert.success, text: response.message })
                  this.isEdit = !this.isEdit
                  this.$router.push({ name: 'campaign-detail', params: this.$route.params.id })
                }
              }
            } catch (error) {
              this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

              if (error.response.status === 401) {
                this.$router.push({ name: 'heroai-logout' })
              }
              if (error.response.status === 404) {
                await this.$router.push({ name: 'page-not-found' })
              }
            }
          }
        } else {
          this.$swal('Please solve the errors in the page.')
        }
      })
    },
    async getProductList() {
      try {
        this.opportunityId = this.$store.getters['heroAiCampaign/getOpportunityId']
        const qparam = { category_id: '0' }
        qparam.opportunity_id = this.opportunityId
        const response = await AxiosService.get(Constant.apiURL.salesforceCampaignProductProductList, qparam)
        if (response.status) {
          this.allproducts = response.data
          this.productOptions = CategoryDropdownFactory.createFromJsonArray(response.data)
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
    },
    getCampaignCycle() {
      const Cycles = this.cycles
      return this.campaignCycle.filter(x => Cycles.indexOf(x.media_plan_id) > -1)
    },
    getActiveCampaignCycle() {
      return this.campaignCycle
    },
    cycleProductSuccess(product) {
      this.popupProduct = product
    },

    async getCampaignProductOptions() {
      const response = await axiosInstance.get(`/dropdown-options/campaign-product-options?campaignId=${this.$route.params.id}`)

      this.campaignProductOptions = response.data
    },

    onCycleFilter(filter) {
      this.cycleFilter = { ...filter }
      const hasCycleShows = []

      if (this.isEdit) {
        this.getCampaignCycle().forEach(cycle => {
          hasCycleShows.push(this.isCycleShow(cycle))
        })
      } else {
        this.getActiveCampaignCycle().forEach(cycle => {
          hasCycleShows.push(this.isCycleShow(cycle))
        })
      }

      if (hasCycleShows.every(cycleShow => !cycleShow)) {
        const toastObject = Toastification.getContentInfo('No cycle found for current filter.')
        this.$toast(toastObject, { timeout: 6000 })
      }
    },

    isCycleShow(cycle) {
      const cycleDate = new Date(cycle.start_date.replace(/-/g, '/'))
      const startDate = this.cycleFilter.start.month ? new Date(+this.cycleFilter.start.year, +this.cycleFilter.start.month - 1, 1) : null
      const endDate = this.cycleFilter.end.month ? new Date(+this.cycleFilter.end.year, +this.cycleFilter.end.month, 0) : null

      let dateFilter = true

      if (startDate && endDate) {
        dateFilter = startDate <= cycleDate && cycleDate <= endDate
      }

      if (startDate && !endDate) {
        dateFilter = startDate <= cycleDate
      }

      if (!startDate && endDate) {
        dateFilter = cycleDate <= endDate
      }

      return (
        cycle.cycle_filter.includes(this.cycleFilter.cycleListFilter)
          || this.cycleFilter.cycleListFilter === 'view_all'
          || (this.cycleFilter.cycleListFilter === 'select_date' && dateFilter)
      )
    },

    onEditCycleSelectChange(mediaPlanId) {
      this.campaignCycle = this.campaignCycle.map(cycle => ({
        ...cycle,
        is_deleted: cycle.media_plan_id === mediaPlanId ? !cycle.is_deleted : cycle.is_deleted,
      }))

      this.$store.commit('heroAiCampaign/setCampaignCycle', this.campaignCycle)
    },

    calculateSfStageVariant(stageName) {
      if (stageName) {
        if (stageName.toLowerCase() === 'go live approval' || stageName.toLowerCase() === 'contract finished') {
          return 'success'
        }
        if (stageName.toLowerCase() === 'closed lost' || stageName.toLowerCase() === 'confirmed lost') {
          return 'danger'
        }
        if (stageName.toLowerCase() === 'meeting' || stageName.toLowerCase() === 'contract sent' || stageName.toLowerCase() === 'signed' || stageName.toLowerCase() === 'heroai campaign created') {
          return 'warning'
        }
      }

      return 'primary'
    },
  },
}
</script>

<style scoped>
.margin {
  margin-top: 7px;
}
.height {
  height: 35px;
  padding-top: 11px;
}
.hrStyle {
  height: 2px;
  border-width: 0;
  color: gray;
  background-color: gray;
}

.text-delete-selected {
  padding: 0.786rem;
}
</style>
